















































































import { Component, Prop } from "vue-property-decorator";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { Gender } from "@/enum/UserTitle.enum";
import { UserDegree } from "@/enum/UserDegree";
import { AUTH_STORE_NAME, AuthStoreActions } from "@/store/auth.store";
import { namespace } from "vuex-class";
import IconTooltipComponent from "@/components/IconTooltip.component.vue";
import { UserType } from "@/enum/UserType.enum";
import { UserRoles } from "@/enum/UserRoles.enum";
import { passwordCriteria } from "@/misc/PasswortCriterias";
import User from "@/models/User.model";
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import { email, minLength, required } from "vuelidate/lib/validators";
import { APPLICATION_STORE_NAME } from "@/store/application.store";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    IconTooltip: IconTooltipComponent,
    DuplicatesList: () => import("@/components/Duplicates.list.component.vue"),
  },
  mixins: [validationMixin],
  validations: {
    newPassword: {
      required,
      minLength: minLength(8)
    },
  }
})
export default class RequestNewPasswordComponent extends mixins(
  ErrorMessageHandlerMixin
) {
  @Prop({ default: User.parseFromObject({}) })
  public user!: User;

  @AuthStore.Action(AuthStoreActions.CHANGE_PASSWORD_FORCE)
  private changePassword!: (payload: { user: User, password: string }) => Promise< User | null>;

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Check if Form is Valid
   * if valid try to Register a new Account and listen for API Responses
   * If Invalid return so Vuelidate handles Errors first
   * @private
   */
  private async changeUserInfo() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
      return;
    }

    if(this.criteriaChecked) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
      return;
    }

    try {
      // Set Loading to true
      this.isLoading = true;
      // logs in the user, checks if the user has the correct role

      await this.changePassword({ user: this.user, password: this.newPassword});
      this.$notifySuccessSimplified(
        "GENERAL.NOTIFICATIONS.REQUEST_NEW_PASSWORD"
      );
      this.dismiss();
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.ERROR_GENERAL");
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * new password input field value
   * @private
   */
  public newPassword: string = '';

  /**
   * flag that indicats if the new password field should be hidden
   * @private
   */
  private hideNewPassword = true;

  /**
   * criterias that needs to fullfilled in order to set the password
   * @private
   */
  private passwordCriteria = [...passwordCriteria];

  /**
   * Validate the password input regarding the password criteria an base criteria (e. g. required)
   * @param value
   */
  public validatePasswordInput(value: string) {
    // base criteria
    this.triggerValidation('newPassword');
    // special password criteria
    this.passwordCriteria.forEach((criterion) => criterion.fullfilled = criterion.regEx.test(value));
  }

  /**
   * checks if every password criteria was checked
   * @private
   */
  private get criteriaChecked() {
    return this.passwordCriteria?.filter((c) => !c.fullfilled).length > 0;
  }
  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit("closeDialog", reload);
  }
}
